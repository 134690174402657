<template>
  <div class="sprites container-fluid">
    <h1 class="page-header primary">
      Sprites
      <span v-if="sprites" class="f5">({{ sprites.length }})</span>
      <SearchBox class="fr f6" v-if="1" :searchList="sprites" />
    </h1>

    <!-- <router-link to="">
      <span>
        <button
          @click="performDelete"
          v-if="checkedIDs.length"
          type="button"
          id="trash"
          class="mr1"
          style="background: url(./font-awesome-svgs/trash-alt.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
        />

        <span @click="performDelete" v-if="checkedIDs.length" class="mr4">
          Delete Selected <span class="f5">({{ checkedIDs.length }})</span>
        </span>
      </span>
    </router-link>

    <router-link to="/addchannel">
      <button
        type="button"
        id="add"
        style="background: url(./font-awesome-svgs/plus-square.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
      />
      Add Channel
    </router-link> -->

    <!-- <span
      @click="toggleAndSaveFilter"
      class="fr blue f7 pointer underline-hover"
    >
      <span v-if="filterAll">(all)</span>
      <span v-else>(my channels)</span>
      <i class="fa fa-filter pl2 f4 gray" />
    </span> -->

    <table class="table table-striped table-borderless table-sm">
      <thead>
        <tr class="bg-gold b">
          <!-- <th>
            <input
              type="checkbox"
              class=""
              v-model="deleteAllCheckBox"
              @change="toggleAllchecked($event)"
            />
          </th> -->
          <th class="th-sm">Name/ID</th>
          <th>Created By</th>
          <th>Created</th>
          <th>Description</th>
          <th>Preview</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="sprite in sprites" :key="sprite.ID">
          <!-- <td>
            <input
              type="checkbox"
              :id="sprite.ID"
              :value="sprite.ID"
              class=""
              v-model="checkedIDs"
              :disabled="
                getSessionObj.userID === sprite.OwnerID ||
                isAdmin(getSessionObj.userID)
                  ? false // if owner or admin, don't disable the checkbox
                  : 'disabled'
              "
            />
          </td> -->

          <td class="">
            <router-link
              class="gray hover-gold"
              v-bind:to="'/sprites/' + sprite.ID"
              >{{ sprite.Name }}
              <div class="f7">{{ sprite.ID }}</div>
            </router-link>
          </td>

          <td>
            <router-link
              class="gray hover-gold"
              v-bind:to="'/users/' + sprite.OwnerID"
              >{{ sprite.OwnerName }}</router-link
            >
          </td>

          <td>{{ sprite.CreatedAt | formatDate }}</td>

          <td>
            <router-link
              class="gray hover-gold"
              v-bind:to="'/sprites/' + sprite.ID"
              >{{ sprite.Description }}</router-link
            >
          </td>

          <td>
            <!-- Image Preview -->
            <img
              style="max-height: 50px; max-width: 50px"
              :src="'data:image/jpeg;base64,' + sprite.ImageData"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import { messageBus } from "@/main";
import Helpers from "@/helpers.js";
import moment from "moment";
import SearchBox from "@/components/SearchBox.vue";
import { mapGetters } from "vuex";

export default {
  name: "Sprites",
  data() {
    return {
      deleteAllCheckBox: false,
      checkedIDs: [],
      filterAll: true,
    };
  },
  computed: {
    ...mapGetters(["getSprites", "getUsers", "getSessionObj"]),
    sprites() {
      let spriteList = this.getSprites;
      spriteList.forEach((sprite) => {
        let user = this.getUsers.find((u) => u.ID === sprite.OwnerID);
        if (user) {
          sprite.OwnerName = user.Name;
        }
      });
      return spriteList;
    },
  },
  methods: {
    eventHandler(topic, msg) {
      console.log("Received event for topic:" + topic + "with msg: ", msg);
    },
    toggleAllchecked(event) {
      if (event.target.checked) {
        // select all
        this.sprites.forEach((sprite) => {
          if (
            this.checkedIDs.includes(sprite.ID) === false &&
            (this.getSessionObj.userID === sprite.OwnerID ||
              this.isAdmin(this.getSessionObj.userID))
          ) {
            this.checkedIDs.push(sprite.ID);
          }
        });
      } else {
        // unselect all
        this.checkedIDs = [];
      }
    },
    performDelete() {
      this.$bvModal
        .msgBoxConfirm("Are you sure?  This action can not be undone.")
        .then((value) => {
          console.log("DELETE CONFIRMATION VALUE: ", value);

          if (!value) {
            return;
          }

          this.checkedIDs.forEach((id) => {
            this.deleteChannel(id);
          });
          this.checkedIDs = [];
          if (this.deleteAllCheckBox) {
            this.deleteAllCheckBox = false;
          }
        })
        .catch((err) => {
          // An error occurred
          // skip delete
          console.log(err);
        });
    },
    deleteChannel(id) {
      console.log("deleting channel");
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .delete(url + "/channels/" + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success deleting channels: ", response.bodyText);
            this.fetchChannels(); // slow way. TODO: just remove from local channels []
            Helpers.showAlert("Channel deleted");
          },
          (response) => {
            // Fail
            console.log("Failed DELETE to /channels:id", response.bodyText);
            if (response.body) {
              Helpers.showAlert("Error: " + response.bodyText);
            } else {
              Helpers.showAlert("Failed DELETE to /channels:id");
            }
          }
        );
    }, // end delete channel
    handleChannelDeletedAction(msg) {
      console.log("handleChannelDeletedAction called");
      Helpers.showAlert(msg);
      this.fetchChannels();
    },
    fetchChannels() {
      this.$store
        .dispatch("apiGetData", {
          url: "/channels",
          commit: true,
          stateProperty: "channels",
          responseProperty: "Items",
          errorAlert: "ERROR: Could not retrieve channels",
        })
        .then((ch) => {
          ch.Items.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    },
    isAdmin() {
      return Helpers.isAdmin(this.getSessionObj.roles);
    },
    toggleAndSaveFilter() {
      this.filterAll = !this.filterAll;
      const parsed = JSON.stringify(this.filterAll);
      localStorage.setItem(
        "channelsFilterAll" + this.getSessionObj.userID,
        parsed
      );
    },
  }, // end methods:
  filters: {
    // capitalize: function (value) {
    //   if (!value) return ''
    //   value = value.toString()
    //   return value.charAt(0).toUpperCase() + value.slice(1)
    // },
    formatDate(value) {
      if (value) {
        //return moment(String(value)).format('MM/DD/YYYY hh:mm')
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  created: function () {
    Helpers.doViewCreation(this);

    messageBus.$on("channel-deleted", this.handleChannelDeletedAction);

    this.$store.dispatch("apiGetData", {
      url: "/sprites",
      commit: true,
      stateProperty: "sprites",
      responseProperty: "Items",
      statePropertyLoaderToToggle: "loadingSprites",
    });
  },
  mounted: function () {},
  components: { SearchBox },
};
</script>
