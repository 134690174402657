<template>
  <div class="template container-fluid">
    <h1 class="page-header">Dev Test Playground</h1>

    <span class="f1">Text to the left</span> <SearchBox class="" /> Text to the
    right

    <ClickToEdit
      value="Hello"
      valueAuxData="1"
      eventToEmit="click-to-edit-fired"
    />

    <!-- <vue-qr :bgSrc='src' :logoSrc="src2" text="Hello world!" :size="200"></vue-qr>
    <vue-qr text="Hello world!" :callback="test" qid="testid"></vue-qr> -->
    <!-- <vue-qr bindElement=false dotScale=".8" text="http://www.edxtech.com/launch?url=https://dev-service.edxtech.com/edxt&username=keith&password=a1s2d3&projectID=03b3827e-b37d-40d8-a5ed-c5b0e6f62dfc" :size="200"></vue-qr> -->

    <!-- <h1>
      Intent
      <a href="intent://www.edxtech.com/launch#Intent;scheme=http;package=com.edx.acxconsole;S.url=https://dev-service.edxtech.com/edxt;S.username=keith;S.password=a1s2d3;S.projectID=03b3827e-b37d-40d8-a5ed-c5b0e6f62dfc;end;">Open App</a>
    </h1>

    <h1>
      http Intent
      <a href="http://www.edxtech.com/launch#Intent;scheme=http;package=com.edx.acxconsole;S.url=https://dev-service.edxtech.com/edxt;S.username=keith;S.password=a1s2d3;S.projectID=03b3827e-b37d-40d8-a5ed-c5b0e6f62dfc;end;">Open App</a>
    </h1>
    <h1>
      Plain URL
      <a href="http://www.edxtech.com/launch?url=https://dev-service.edxtech.com/edxt&username=keith&password=a1s2d3&projectID=03b3827e-b37d-40d8-a5ed-c5b0e6f62dfc">Open App</a>
    </h1> -->

    <h1>getTestArray</h1>
    <button @click="changeTestArrayValue">Change TestArray Obj2 Val</button>

    <div v-for="(item, idx) in getTestArray" :key="idx">
      <pre>{{ item }}</pre>
    </div>

    <h1>getChannels</h1>
    <button @click="showChannels = !showChannels">show/hide channels</button>
    <button
      @click="
        $store.dispatch('apiGetData', {
          url: '/channels',
          commit: true,
          stateProperty: 'channels',
          responseProperty: 'Items',
        })
      "
    >
      Get Channels
    </button>
    <div v-if="showChannels">
      <div v-for="channel in channels" :key="channel.ID">
        <pre>{{ channel }}</pre>
      </div>
    </div>

    <h1>getUsers</h1>
    <button @click="showUsers = !showUsers">show/hide users</button>
    <button
      @click="
        $store.dispatch('apiGetData', {
          url: '/users',
          commit: true,
          stateProperty: 'users',
          responseProperty: 'Items',
        })
      "
    >
      Get Users
    </button>
    <div v-if="showUsers">
      <div v-for="user in users" :key="user.ID">
        <pre>{{ user }}</pre>
      </div>
    </div>

    <h1>getProjects</h1>
    <button @click="showProjects = !showProjects">show/hide users</button>
    <button
      @click="
        $store.dispatch('apiGetData', {
          url: '/projects',
          commit: true,
          stateProperty: 'projects',
          responseProperty: 'Items',
        })
      "
    >
      Get Projects
    </button>
    <div v-if="showProjects">
      <div v-for="project in projects" :key="project.ID">
        <pre>{{ project }}</pre>
      </div>
    </div>

    <div>
      <h1>getSessionObj</h1>
      <button @click="showSessionInfo = !showSessionInfo">
        show/hide session
      </button>
      <div v-if="showSessionInfo">
        <pre>{{ getSessionObj }}</pre>
        <h1>password</h1>
        <pre>{{ getSessionPassword }}</pre>
        <button @click="changeStatePassword">Change password</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { messageBus } from '@/main';
import Helpers from '@/helpers.js';
import ClickToEdit from '@/components/ClickToEdit.vue';
import SearchBox from '@/components/SearchBox.vue';
//import VueQr from 'vue-qr';
import { mapGetters } from 'vuex';

export default {
  name: 'DevTest',
  data() {
    return {
      showSessionInfo: false,
      showChannels: false,
      showProjects: false,
      showUsers: false,
    };
  },
  computed: {
    ...mapGetters([
      'getChannels',
      'getUsers',
      'getProjects',
      'getSessionObj',
      'getSessionPassword',
      'getTestArray',
    ]),
    channels() {
      let myChannels = this.getChannels;
      myChannels.forEach((c) => {
        let user = this.getUsers.find((u) => u.ID === c.OwnerID);
        if (user) {
          c.OwnerName = user.Name;
        }
      });
      return myChannels;
    },
    projects() {
      let myProjects = this.$store.getters.getProjects;
      myProjects.forEach((p) => {
        let user = this.getUsers.find((u) => u.ID === p.OwnerID);
        if (user) {
          p.OwnerName = user.Name;
        }
      });
      return myProjects;
    },
    users() {
      let myUsers = this.$store.getters.getUsers;
      myUsers.forEach((p) => {
        let user = this.getUsers.find((u) => u.ID === p.OwnerID);
        if (user) {
          p.OwnerName = user.Name;
        }
      });
      return myUsers;
    },
  },
  methods: {
    eventHandler(msg) {
      Helpers.showAlert('Alert triggered with msg: ' + msg);
    },
    eventHandlerClickToEditFired(msg) {
      Helpers.showAlert(
        'ClickToEditFired with aux: ' +
          msg.valueAuxData +
          ' value: ' +
          msg.value
      );
    },
    changeStatePassword() {
      let p = Math.random()
        .toString(36)
        .substring(7);
      this.$store.commit('MUTATE', { property: 'sessionPassword', with: p });
    },
    changeTestArrayValue() {
      let p = Math.random();
      let obj = this.$store.getters.getTestArray[1];
      obj.value = p;
      //this.$store.commit('OBJ_MUTATE_APPEND', obj);
    },
  },
  created: function() {
    console.log('DEVTEST: Created called');

    // Call our base-class-like setup function
    Helpers.doViewCreation(this);

    // Set up a listener for messages for events of type TOPIC-TO-LISTEN-TO
    messageBus.$on('TOPIC-TO-LISTEN-TO', this.eventHandler);

    messageBus.$on('click-to-edit-fired', this.eventHandlerClickToEditFired);
  },
  mounted: function() {
    console.log('DEVTEST: Mounted called');
  },
  //components: { ClickToEdit, VueQr }
  components: { ClickToEdit, SearchBox },
};
</script>
