<template>
  <div class="spritedetail container-fluid">
    <h1 class="page-header primary">
      Sprite Details
    </h1>
    <p class="f3 fw5 tracked">Name: {{ sprite.Name }}</p>

    <div class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <tbody>
          <tr>
            <td>Name</td>
            <td class>
              <span v-if="!canEdit()">{{ sprite.Name }}</span>
              <span v-else>
                <ClickToEdit
                  :value="sprite.Name"
                  :valueAuxData="sprite.ID"
                  eventToEmit="event-sprite-detail-name-change"
                ></ClickToEdit>
              </span>
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              <span v-if="canEdit()">
                <ClickToEdit
                  :value="sprite.Description"
                  :valueAuxData="sprite.ID"
                  eventToEmit="event-sprite-detail-description-change"
                />
              </span>
              <span v-else>{{ sprite.Description }}</span>
            </td>
          </tr>
          <tr>
            <td>ID</td>
            <td>{{ sprite.ID }}</td>
          </tr>
          <tr>
            <td>Owner Name/ID</td>
            <td>
              <router-link
                class="gray hover-gold"
                v-bind:to="'/users/' + sprite.OwnerID"
              >
                {{ sprite.OwnerName }}
                <div class="f7">{{ sprite.OwnerID }}</div>
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Preview</td>
            <td>
              <img style="height: auto; width: auto" :src="getImageData()" />
            </td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{{ sprite.CreatedAt }}</td>
          </tr>
          <tr>
            <td>Updated</td>
            <td>{{ sprite.UpdatedAt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from '@/helpers.js';
// import APIhelper from '@/api/apihelper.js';
import { messageBus } from '@/main';
import ClickToEdit from '@/components/ClickToEdit.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SpriteDetail',
  data() {
    return {
      sprite: {},
      projLayerDependencies: [],
    };
  },
  computed: mapGetters(['getSessionObj', 'getProjects']),
  methods: {
    fetchSprite(id) {
      console.log('fetching sprite with id: ', id);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + '/sprites/' + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success getting /sprites:' + id, response.bodyText);
            this.sprite = response.body;
            this.fetchSpriteOwner();
          },
          response => {
            // Fail
            console.log('Failed to get /sprites/:id ', response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /sprites/:id");
          },
        );
    }, // end fetch sprites
    fetchSpriteOwner() {
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + '/users/' + this.sprite.OwnerID, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success getting /users/+id', response.body);
            this.$set(this.sprite, 'OwnerName', response.body.Name);
            // this.$forceUpdate();
          },
          response => {
            // Fail
            console.log('Failed to get /users/+id ', response.body);
            Helpers.showAlert("ERROR: Couldn't retrieve /users/+id");
          },
        );
    },
    canEdit() {
      return Helpers.isAdminOrOwner(this.sprite.OwnerID);
    },
    putSprite() {
      console.log('updating sprite name for sprite: ', this.sprite.Name);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL + '/sprites/' + this.$route.params.id;
      console.log('URL: ', url);

      this.$http
        .put(url, this.sprite, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success updating sprite', response.bodyText);
          },
          response => {
            // Fail
            console.log('Failed to update sprite name ', response.bodyText);
            Helpers.showAlert(
              'ERROR: Failed to update layer ' + response.bodyText,
            );
          },
        );
    }, // end putSprite
    handleEventSpriteDetailDescriptionChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.sprite.ID
      ) {
        this.sprite.Description = msg.value;
        this.$set(this.sprite, 'Description', msg.value);
        this.putSprite();
      }
    },
    handleEventSpriteDetailNameChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.sprite.ID
      ) {
        this.sprite.Name = msg.value;
        this.$set(this.sprite, 'Name', msg.value);
        this.putSprite();
      }
    },
    getImageData() {
      return 'data:image/jpeg;base64,' + this.sprite.ImageData;
    },
    isAdmin() {
      return Helpers.isAdmin(this.getSessionObj.roles);
    },
  },
  created: function() {
    console.log('CREATED: /sprite');
    Helpers.doViewCreation(this);
    this.fetchSprite(this.$route.params.id);

    messageBus.$on(
      'event-sprite-detail-name-change',
      this.handleEventSpriteDetailNameChange,
    );

    messageBus.$on(
      'event-sprite-detail-description-change',
      this.handleEventSpriteDetailDescriptionChange,
    );
  },
  mounted: function() {},
  components: { ClickToEdit },
};
</script>
