var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template container-fluid"},[_c('h1',{staticClass:"page-header"},[_vm._v("Dev Test Playground")]),_c('span',{staticClass:"f1"},[_vm._v("Text to the left")]),_vm._v(" "),_c('SearchBox',{}),_vm._v(" Text to the right "),_c('ClickToEdit',{attrs:{"value":"Hello","valueAuxData":"1","eventToEmit":"click-to-edit-fired"}}),_c('h1',[_vm._v("getTestArray")]),_c('button',{on:{"click":_vm.changeTestArrayValue}},[_vm._v("Change TestArray Obj2 Val")]),_vm._l((_vm.getTestArray),function(item,idx){return _c('div',{key:idx},[_c('pre',[_vm._v(_vm._s(item))])])}),_c('h1',[_vm._v("getChannels")]),_c('button',{on:{"click":function($event){_vm.showChannels = !_vm.showChannels}}},[_vm._v("show/hide channels")]),_c('button',{on:{"click":function($event){return _vm.$store.dispatch('apiGetData', {
        url: '/channels',
        commit: true,
        stateProperty: 'channels',
        responseProperty: 'Items',
      })}}},[_vm._v(" Get Channels ")]),(_vm.showChannels)?_c('div',_vm._l((_vm.channels),function(channel){return _c('div',{key:channel.ID},[_c('pre',[_vm._v(_vm._s(channel))])])}),0):_vm._e(),_c('h1',[_vm._v("getUsers")]),_c('button',{on:{"click":function($event){_vm.showUsers = !_vm.showUsers}}},[_vm._v("show/hide users")]),_c('button',{on:{"click":function($event){return _vm.$store.dispatch('apiGetData', {
        url: '/users',
        commit: true,
        stateProperty: 'users',
        responseProperty: 'Items',
      })}}},[_vm._v(" Get Users ")]),(_vm.showUsers)?_c('div',_vm._l((_vm.users),function(user){return _c('div',{key:user.ID},[_c('pre',[_vm._v(_vm._s(user))])])}),0):_vm._e(),_c('h1',[_vm._v("getProjects")]),_c('button',{on:{"click":function($event){_vm.showProjects = !_vm.showProjects}}},[_vm._v("show/hide users")]),_c('button',{on:{"click":function($event){return _vm.$store.dispatch('apiGetData', {
        url: '/projects',
        commit: true,
        stateProperty: 'projects',
        responseProperty: 'Items',
      })}}},[_vm._v(" Get Projects ")]),(_vm.showProjects)?_c('div',_vm._l((_vm.projects),function(project){return _c('div',{key:project.ID},[_c('pre',[_vm._v(_vm._s(project))])])}),0):_vm._e(),_c('div',[_c('h1',[_vm._v("getSessionObj")]),_c('button',{on:{"click":function($event){_vm.showSessionInfo = !_vm.showSessionInfo}}},[_vm._v(" show/hide session ")]),(_vm.showSessionInfo)?_c('div',[_c('pre',[_vm._v(_vm._s(_vm.getSessionObj))]),_c('h1',[_vm._v("password")]),_c('pre',[_vm._v(_vm._s(_vm.getSessionPassword))]),_c('button',{on:{"click":_vm.changeStatePassword}},[_vm._v("Change password")])]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }