<template>
  <div id="clientdownloads" class="users container-fluid">
    <h1 class="page-header primary">Client Downloads</h1>

    <div v-if="this.osPlatform == 'MacOS' || showAllPlatforms">
      <hr />
      <p class="f3 fw5 tracked">
        <i class="fa fa-apple" aria-hidden="true"></i> | Mac OS X Desktop
      </p>
      <a
        :href="baseDownloadUrl + '/' + downloadFileNamePrefix + 'osx.zip'"
        class="gray hover-gold"
      >
        <i class="fa fa-download" aria-hidden="true"></i>
        Download for Mac OS X
      </a>
    </div>
    <div v-if="this.osPlatform == 'Windows' || showAllPlatforms">
      <hr />
      <p class="f3 fw5 tracked">
        <i class="fa fa-windows" aria-hidden="true"></i> | Windows Desktop
      </p>
      <a
        :href="
          baseDownloadUrl + '/' + downloadFileNamePrefix + 'ConsoleSetup.exe'
        "
        class="gray hover-gold"
      >
        <i class="fa fa-download" aria-hidden="true"></i>
        Download for Windows
      </a>
    </div>
    <div v-if="this.osPlatform == 'iOS' || showAllPlatforms">
      <hr />
      <div class="f3 fw5 tracked">
        <i
          class="fa fa-mobile"
          style="font-size: 150%; margin-right: 8px"
          aria-hidden="true"
        ></i>
        <div
          style="vertical-align: middle; display: inline; position: absolute"
        >
          | iOS Mobile
        </div>
      </div>
      The EDXT iOS App is currently available through TestFlight<br /><br />
      <a
        href="https://apps.apple.com/us/app/testflight/id899247664?mt=8"
        style="display:inline-block;overflow:hidden;background:url(/svg/iTunes-badge-lrg.svg) no-repeat;width:135px;height:40px;"
      ></a>
    </div>
    <div v-if="this.osPlatform == 'Android' || showAllPlatforms">
      <hr />
      <div class="f3 fw5 tracked">
        <i
          class="fa fa-mobile"
          style="font-size: 150%; margin-right: 8px"
          aria-hidden="true"
        ></i>
        <div
          style="vertical-align: middle; display: inline; position: absolute"
        >
          | Android Mobile
        </div>
      </div>
      <a
        :href="baseDownloadUrl + '/' + downloadFileNamePrefix + 'android.apk'"
        class="gray hover-gold"
      >
        <i class="fa fa-download" aria-hidden="true"></i>
        Download for Android
      </a>
    </div>
    <div v-if="this.osPlatform == 'Unknown'">
      <hr />
      Unsupported Platform
    </div>

    <div @click="showAllPlatforms = !showAllPlatforms" class="f5 mv4">
      <hr />
      <div v-if="!showAllPlatforms">
        Looking for a different platform?
        <span class="gray hover-gold pointer">Show all download options</span>.
      </div>
      <div v-if="showAllPlatforms">
        Only
        <span class="gray hover-gold pointer"
          >show downloads for my platform</span
        >.
      </div>
    </div>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';

export default {
  name: 'ClientDownloads',
  data() {
    return {
      osPlatform: 'Unknown',
      downloadTag: null,
      showAllPlatforms: false,
      baseClientURL: 'https://github.com/EDXT/Platform/releases/download/',
      downloadFileNamePrefix: 'EDXTUnstable_',
    };
  },
  computed: {
    baseDownloadUrl() {
      return this.baseClientURL + this.downloadTag;
    },
  },
  methods: {
    async fetchFile(fileName) {
      console.log("Fetching File " + fileName);
      let url = this.$router.options.base + fileName;
      console.log('URL: ', url);
      return(await this.$http.get(url));
    }
  }, // end methods
  filters: {},
  created() {
    Helpers.doViewCreation(this);

    // READ base download URL from an ENV.
    // The full download url is a combination of
    //    baseClientURL + releaseTag + clientFileName
    // From the ENV, we just need to read baseClientURL
    // Note that we've chosen to put the releaseTag in a file, read from disk,
    // so that it is easily changed by automation and read at run time by this app
    // as well as other apps.  We are putting the filename prefix and URL in
    // ENV vars, which can be changed on the local system, but require app restart
    if (!process.env.VUE_APP_CLIENT_DOWNLOAD_BASEURL) {
      console.log(
        'No client download base URL set. Using default: ' + this.baseClientURL
      );
      this.fetchFile("clienturl.txt").then(response => this.baseClientURL = response.body)
      this.fetchFile("clientprefix.txt").then(response => this.downloadFileNamePrefix = response.body)
    } else {
      this.baseClientURL = process.env.VUE_APP_CLIENT_DOWNLOAD_BASEURL;
    }

    this.fetchFile("clientversion.txt").then(response => this.downloadTag = response.body)
    // Get the download filename prefix from an ENV
    if (!process.env.VUE_APP_CLIENT_DOWNLOAD_PREFIX) {
      console.log(
        'No client download base URL set. Using default: ' +
          this.downloadFileNamePrefix
      );
    } else {
      this.downloadFileNamePrefix = process.env.VUE_APP_CLIENT_DOWNLOAD_PREFIX;
    }
  },
  mounted() {
    if (navigator.platform.indexOf('Win') != -1) this.osPlatform = 'Windows';
    if (navigator.platform.indexOf('Mac') != -1) this.osPlatform = 'MacOS';
    if (navigator.platform.indexOf('Linux') != -1) this.osPlatform = 'Linux';
    if (navigator.platform.indexOf('iPhone') != -1) this.osPlatform = 'iOS';
    if (navigator.platform.indexOf('Android') != -1)
      this.osPlatform = 'Android';
  },
};
</script>
