<template>
  <div class="shapedetail container-fluid">
    <h1 class="page-header primary">Shape Details</h1>
    <p class="f3 fw5 tracked">Name: {{ shape.Name }}</p>

    <div class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <tbody>
          <tr>
            <td>Name</td>
            <td class>
              <span v-if="!canEdit()">{{ shape.Name }}</span>
              <span v-else>
                <ClickToEdit
                  :value="shape.Name"
                  :valueAuxData="shape.ID"
                  eventToEmit="event-shape-detail-name-change"
                ></ClickToEdit>
              </span>
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              <span v-if="canEdit()">
                <ClickToEdit
                  :value="shape.Description"
                  :valueAuxData="shape.ID"
                  eventToEmit="event-shape-detail-description-change"
                />
              </span>
              <span v-else>{{ shape.Description }}</span>
            </td>
          </tr>
          <tr>
            <td>ID</td>
            <td>{{ shape.ID }}</td>
          </tr>
          <tr>
            <td>Owner Name/ID</td>
            <td>
              <router-link
                class="gray hover-gold"
                v-bind:to="'/users/' + shape.OwnerID"
              >
                {{ shape.OwnerName }}
                <div class="f7">{{ shape.OwnerID }}</div>
              </router-link>
            </td>
          </tr>
          <!-- <tr>
            <td>Preview</td>
            <td>
              <img style="height: auto; width: auto" :src="getImageData()" />
            </td>
          </tr> -->
          <tr>
            <td>Created</td>
            <td>{{ shape.CreatedAt }}</td>
          </tr>
          <tr>
            <td>Updated</td>
            <td>{{ shape.UpdatedAt }}</td>
          </tr>
        </tbody>
      </table>
      <JSONViewer :json="shape" label="Raw JSon" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
// import APIhelper from '@/api/apihelper.js';
import { messageBus } from "@/main";
import ClickToEdit from "@/components/ClickToEdit.vue";
import { mapGetters } from "vuex";
import JSONViewer from "@/components/JSONViewer.vue";

export default {
  name: "ShapeDetail",
  data() {
    return {
      shape: {},
      projLayerDependencies: [],
    };
  },
  computed: mapGetters(["getSessionObj", "getProjects"]),
  methods: {
    fetchShape(id) {
      console.log("fetching shape with id: ", id);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/shapes/" + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success getting /shapes:" + id, response.bodyText);
            this.shape = response.body;
            this.fetchShapeOwner();
          },
          (response) => {
            // Fail
            console.log("Failed to get /shapes/:id ", response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /shapes/:id");
          }
        );
    }, // end fetch shapes
    fetchShapeOwner() {
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/users/" + this.shape.OwnerID, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success getting /users/+id", response.body);
            this.$set(this.shape, "OwnerName", response.body.Name);
            // this.$forceUpdate();
          },
          (response) => {
            // Fail
            console.log("Failed to get /users/+id ", response.body);
            Helpers.showAlert("ERROR: Couldn't retrieve /users/+id");
          }
        );
    },
    canEdit() {
      return Helpers.isAdminOrOwner(this.shape.OwnerID);
    },
    putShape() {
      console.log("updating shape name for shape: ", this.shape.Name);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL + "/shapes/" + this.$route.params.id;
      console.log("URL: ", url);

      this.$http
        .put(url, this.shape, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success updating shape", response.bodyText);
          },
          (response) => {
            // Fail
            console.log("Failed to update shape name ", response.bodyText);
            Helpers.showAlert(
              "ERROR: Failed to update layer " + response.bodyText
            );
          }
        );
    }, // end putShape
    handleEventShapeDetailDescriptionChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.shape.ID
      ) {
        this.shape.Description = msg.value;
        this.$set(this.shape, "Description", msg.value);
        this.putShape();
      }
    },
    handleEventShapeDetailNameChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.shape.ID
      ) {
        this.shape.Name = msg.value;
        this.$set(this.shape, "Name", msg.value);
        this.putShape();
      }
    },
    getImageData() {
      return "data:image/jpeg;base64," + this.shape.ImageData;
    },
    isAdmin() {
      return Helpers.isAdmin(this.getSessionObj.roles);
    },
  },
  created: function () {
    console.log("CREATED: /shape");
    Helpers.doViewCreation(this);
    this.fetchShape(this.$route.params.id);

    messageBus.$on(
      "event-shape-detail-name-change",
      this.handleEventShapeDetailNameChange
    );

    messageBus.$on(
      "event-shape-detail-description-change",
      this.handleEventShapeDetailDescriptionChange
    );
  },
  mounted: function () {},
  components: { ClickToEdit, JSONViewer },
};
</script>
